import React from 'react';
import { AuthProvider } from "./hooks/useAuth";
import { Route, Routes } from 'react-router-dom';
import { useAuth } from "./hooks/useAuth";
import { Navigate } from "react-router-dom";
import AuthLayout from './components/Layouts/AuthLayout';
import Dashboard from './pages/Dashboard/Dashboard';
import Agenda from './pages/Agenda/Agenda';
import CreateAgenda from './pages/Agenda/CreateAgenda';
import Login from './pages/Login/Login';
import Logout from './pages/Logout';
import Element from './pages/Element/Element';
import BookingInfo from './pages/BookingInfo';

const ProtectedRoutes = () => {
  const { user } = useAuth();
  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <AuthLayout>
      <Routes>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="agenda/:id" element={<Agenda />} />
        <Route path="create-agenda" element={<CreateAgenda />} />
        <Route path="elementos" element={<Element />} />
        <Route path="logout" element={<Logout />} />
      </Routes>
    </AuthLayout>
  );
};

const App = () => (
  <div className="App">
    <AuthProvider>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path='/reserva' element={<BookingInfo />} />
        <Route path="/*" element={<ProtectedRoutes />} />
      </Routes>
    </AuthProvider>    
  </div>
);

export default App;