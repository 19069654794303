import { useEffect, useState } from "react";
import elementoService from "../../services/elemento/elemento-service";
import { Button, Card, Col, Form, Input, Row, Space, Typography, message } from "antd";
import { ProductOutlined } from "@ant-design/icons";
import ElementForm from "../../components/Element/ElementForm";

const { Title } = Typography;

export default function Element() {

  const [elements, setElements] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    const getElements = async () => {
      const response = await elementoService.getElements()
      setElements(response)
    }
    getElements()
  }, [])

  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      const body = {
        nombre: values.nombre,
        codigo: values.codigo,
        descripcion: ''
      }

      await elementoService.createElement(body);

      const response = await elementoService.getElements()
      setElements(response)

      form.resetFields();
      message.success('Elemento creado exitosamente');
    } catch (error) {
      if (error.response) {
        message.error(error.response.data?.error);
      } else {
        message.error('Error al crear el elemento');
      }
    } finally {
      setIsLoading(false);
    }
  }


  return (
    <div className="pa-10">
      <Space align="baseline" className="mb-5">
        <ProductOutlined style={{ fontSize: '20px' }}/>
        <Typography.Title level={3}>Lista de elementos</Typography.Title>
      </Space>
      <Row gutter={[24, 24]}>
        {elements.map(element => (
          <Col sm={24} md={12} key={element.id}>
            <Card 
              title={<Title level={5} className="ma-0">{element.nombre}</Title>}
              className="h-full" 
            >
              <ElementForm elementId={element.id} />
            </Card>
          </Col>
        ))}
        <Col sm={24} md={12}>
          <Typography.Title level={4}>Agregar nuevo elemento</Typography.Title>
          <Form
            form={form}
            onFinish={onFinish}
          >
            <Form.Item name="nombre" rules={[{ required: true, message: 'Ingrese el nombre' }]}>
              <Input placeholder="Nombre del elemento" />
            </Form.Item>
            <Form.Item name="codigo" rules={[{ required: true, message: 'Ingrese el código', max: 20 }]}>
              <Input placeholder="Codigo del elemento" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isLoading}>Guardar</Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  )
}