import React, { useState } from 'react';
import dayjs from 'dayjs';
import { Form, Modal, Input, Button, TimePicker, DatePicker, Space, Divider, InputNumber, Typography, message, Select, Row, Col, Switch } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import Title from 'antd/es/typography/Title';
import agendaService from '../../services/agenda/agenda-service';
import { useSelector } from 'react-redux';

const { RangePicker } = TimePicker;
const { Text } = Typography;

const daysOfWeek = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
const daysOfWeekKeys = ['lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado', 'domingo'];

const UpdateAgendaForm = ({ onSuccess }) => {

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const horario = [];
  const agenda = useSelector(state => state.agenda.selectedAgenda);

  // fill the horario array with the values from the agenda
  agenda.horarios.forEach(horarioDia => {
    const dia = horarioDia.dia;
    const inicio = dayjs(horarioDia.horaInicio, 'HH:mm');
    const fin = dayjs(horarioDia.horaFin, 'HH:mm');
    horario.push({ dia, horario: [inicio, fin] });
  });

  const initialValues = {
    nombre: agenda.nombre,
    codigo: agenda.codigo,
    intervalo: agenda.configuracion.find(conf => conf.tipo === 'intervalo').valor,
    cupos: agenda.configuracion.find(conf => conf.tipo === 'cupos').valor,
    horario,
    asuetos: agenda.asuetos.map(asueto => ({
      fecha: dayjs(asueto.fecha),
      nombre: asueto.nombre
    }))
  };


  const onFinish = async (values) => {
    try {
      setLoading(true);

      const body = {
        nombre: values.nombre,
        codigo: values.codigo,
        configuracion: {
          intervalo: values.intervalo,
          cupos: values.cupos,
          horario: values.horario.map((day) => {
            return {
              dia: day.dia,
              inicio: day.horario[0].format('HH:mm'),
              fin: day.horario[1].format('HH:mm')
            };
          }),
          asuetos: values.asuetos
        }
      };

      if (body.configuracion.horario.length === 0) {
        message.error('Debe seleccionar al menos un día de atención');
        return;
      }

      await agendaService.updateAgenda(agenda.id, body);
      await agendaService.getAgendaById(agenda.id);

      message.success('Agenda actualizada correctamente');

      await agendaService.getMyAgendas();

      onSuccess();

    } catch (error) {
      if (error.response) {
        message.error(error.response.data?.error);
      } else {
        message.error('Error al actualizar la agenda');
      }
    } finally {
      setLoading(false);
    }
  };

  const onStatusChange = async (checked) => {
    try {
      setLoading(true);
      await agendaService.toogleAgendaStatus(agenda.id);
      await agendaService.getAgendaById(agenda.id);
      message.success('Estado de la agenda actualizado correctamente');
    } catch (error) {
      if (error.response) {
        message.error(error.response.data?.error);
      } else {
        message.error('Error al actualizar el estado de la agenda');
      }
    } finally {
      setLoading(false);
    }
  }

  const onDelete = async () => {
    try {
      await Modal.confirm({
        title: 'Confirmar eliminación',
        content: (
          <>
            <div className='mb-3'>
              Para confirmar la eliminación de la agenda, ingrese el nombre de la agenda
              <Text code>{agenda.nombre}</Text>
            </div>
            <Input placeholder="Nombre de la agenda" />
          </>
        ),
        okText: 'Eliminar',
        cancelText: 'Cancelar',
        onOk: async () => {
          if (agenda.nombre === document.querySelector('.ant-modal-content input').value) {
            await agendaService.deleteAgenda(agenda.id);
            message.info('Agenda eliminada correctamente');
            navigate('/')
          } else {
            message.error('El nombre ingresado no coincide con el nombre de la agenda');
          }
        },
      });
    } catch (error) {
      if (error.response) {
        message.error(error.response.data?.error);
      } else {
        message.error('Error al eliminar la agenda');
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        initialValues={initialValues}
      >

        <Row justify="space-between">
          <Col>
            <Title level={5} className='mt-0'>{ agenda.nombre }</Title>
          </Col>
          <Col>
            <Switch checked={agenda.activo} onChange={onStatusChange} className='mt-0' loading={loading}/>
          </Col>
        </Row>

        <Divider />

        <Form.Item label="Nombre de la agenda" name="nombre" rules={[{ required: true, message: 'Ingrese el nombre de la agenda' }]}>
          <Input />
        </Form.Item>

        <Form.Item 
          label="Código de la agenda" 
          name="codigo" 
          rules={[{ required: true, message: 'Ingrese el código de la agenda'}, { max: 50,  message: 'El código no puede tener más de 50 caracteres' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Intervalo de tiempo para cada cita" name="intervalo" rules={[{ required: true, message: 'Ingrese el intervalo de tiempo' }]}>
          <InputNumber addonAfter="minutos" />
        </Form.Item>

        <Form.Item label="Citas por intervalo" name="cupos" rules={[{ required: true, message: 'Ingrese la cantidad' }]}>
          <InputNumber min={1} />
        </Form.Item>

        <Divider />
        <Title level={4}>Horarios de atención</Title>

        <Form.List name="horario">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }, index) => (
                <Space key={key} className='flex mt-5' align="baseline">
                  <Form.Item
                    {...restField}
                    name={[name, 'dia']}
                    rules={[{ required: true, message: 'Seleccione un día' }]}
                  >
                    <Select placeholder="Seleccione un día">
                      {daysOfWeek.map((day, dayIndex) => (
                        <Select.Option key={dayIndex} value={daysOfWeekKeys[dayIndex]}>
                          {day}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'horario']}
                  >
                    <RangePicker
                      format="HH:mm"
                      placeholder={['Inicio', 'Fin']}
                      minuteStep={15}
                      required={true}
                    />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                  Agregar dia
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <Divider />
        <Title level={4}>Asuetos</Title>

        <Form.List name="asuetos">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space key={key} className='flex mt-5' align="baseline">
                  <Form.Item {...restField} name={[name, 'fecha']}>
                    <DatePicker placeholder='Seleccione la fecha' />
                  </Form.Item>
                  <Form.Item {...restField} name={[name, 'nombre']}>
                    <Input placeholder="Nombre del asueto" />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                  Agregar asueto
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading} block>
            Guardar
          </Button>
        </Form.Item>
      </Form>
      <Divider />
      <div>
        <Button onClick={onDelete} type="primary" danger block>Eliminar agenda</Button>
      </div>
    </>
  );
};

export default UpdateAgendaForm;
