import React, { useEffect, useState } from 'react';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';
import { Layout, Button, theme, Grid, Row, Col } from 'antd';
import { Footer } from 'antd/es/layout/layout';
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import authService from '../../services/auth/auth-service';
import AgendaService from '../../services/agenda/agenda-service';
import AuthMenu from './Menu/AuthMenu';
import './AuthLayout.css';

const { Header, Sider, Content } = Layout;
const { useBreakpoint } = Grid;

const AuthLayout = (props) => {
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const { user } = useAuth();

  const [collapsed, setCollapsed] = useState(false);

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  

  useEffect(() => {
    const verifyToken = async () => {
      if (!user) {
        navigate('/login');
        return;
      }
  
      try {
        await authService.verifyToken(user.token);
      } catch (error) {
        console.error('Error al verificar el token:', error);
        navigate('/login');
      }
    };

    const loadData = async () => {
      const agendas = await AgendaService.getMyAgendas();

      if (window.location.pathname !== '/') {
        return;
      }
  
      if (agendas.length > 0) {
        navigate(`/agenda/${agendas[0].id}`);
      } else if (user.role.nombre === 'Administrador') {
        navigate('/create-agenda');
      } 
    }

  
    verifyToken();
    loadData();
  }, [user]);

  useEffect(() => {
    setCollapsed(false)
  }, [screens]);


  return (
    <Layout hasSider>
      <Sider 
        trigger={null}
        style={{ 
          overflow: "auto",
          height: "100vh",
          position: "sticky",
          top: 0,
          left: 0,
          background: colorBgContainer 
        }}
        width={screens.md ? 200 : 0}
        collapsed={collapsed}
      >
        <div className="flex items-center pa-5">
          <img src="https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg" alt="logo" height={32}/>
          {
            !collapsed && <span className='ml-3 font-bold'>Agenda</span>
          }
        </div>
        <AuthMenu />
      </Sider>
      <Layout>
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        >
          <Row justify="space-between">
            <Col>
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
                style={{
                  fontSize: '16px',
                  width: 64,
                  height: 64,
                }}
              />
            </Col>
            <Col>
                <span className='px-3'>{ user.company?.nombre } / id: { user.company?.id }</span>
            </Col>
          </Row>
        </Header>
        <Content
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          {props.children}
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          Calendar ©{new Date().getFullYear()}
        </Footer>
      </Layout>
    </Layout>
  );
};
export default AuthLayout;