import { useEffect, useState } from "react";
import elementoService from "../../services/elemento/elemento-service";
import ElementForm from "./ElementForm";
import { Divider } from "antd";
import { useSelector } from "react-redux";

export default function Elements() {

  const [elements, setElements] = useState([]);

  const agenda = useSelector(state => state.agenda.selectedAgenda);

  useEffect(() => {
    const getElements = async () => {
      const agendaOptions = agenda.opciones || []

      // group agendaOptions by idElemento
      const groupedOptions = agendaOptions.reduce((acc, option) => {
        acc[option.idElemento] = acc[option.idElemento] || []
        acc[option.idElemento].push(option)
        return acc
      }, {})

      const elements = await elementoService.getElements()

      // sort elements by most selected options
      elements.sort((a, b) => {
        const legthA = groupedOptions[a.id]?.length || 0
        const legthB = groupedOptions[b.id]?.length || 0
        
        return legthB - legthA
      })

      setElements(elements)
    }

    getElements()
  }, [agenda.opciones])

  return (
    <div>
      {elements.map(element => (
        <div key={element.id}>
          <ElementForm elementId={element.id}/>
          <Divider/>
        </div>
      ))}
    </div>
  )
}