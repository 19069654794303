// store.js

import { combineReducers, createStore } from 'redux';
import agendaReducer from './agenda-reducer';
import bookingReducer from './booking-reducer';

const reducers = combineReducers({
  agenda: agendaReducer,
  booking: bookingReducer
});

const store =  createStore(reducers);

export default store;