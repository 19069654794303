// LoginComponent.js

import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import AuthService from '../../services/auth/auth-service';

function Login() {
  const [loading, setLoading] = useState(false);
  const { login } = useAuth();
  const navigate = useNavigate();

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const user = await AuthService.login(values.username, values.password);
      await login(user);
      navigate('/')
    } catch (error) {
      console.log(error.response);
      message.error(error.response.data.error);
    }
    setLoading(false);
  };


  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    message.error('Por favor completa el formulario');
  };

  return (
    <div style={{ width: 300, margin: 'auto', marginTop: 100 }}>
      <h2 style={{ textAlign: 'center' }}>Iniciar Sesión</h2>
      <Form
        name="loginForm"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          name="username"
          rules={[{ required: true, message: 'Por favor ingresa tu nombre de usuario' }]}
        >
          <Input placeholder="Nombre de usuario" />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Por favor ingresa tu contraseña' }]}
        >
          <Input.Password placeholder="Contraseña" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" block loading={loading}>
            Iniciar Sesión
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default Login;
