import React, { useState } from 'react';
import { Form, Input, Button, TimePicker, DatePicker, Space, Divider, InputNumber, message, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import Title from 'antd/es/typography/Title';
import agendaService from '../../services/agenda/agenda-service';
import { useNavigate } from 'react-router-dom';

const { RangePicker } = TimePicker;

const daysOfWeek = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
const daysOfWeekKeys = ['lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado', 'domingo'];

const CreateAgendaForm = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      console.log(values);
      const body = {
        nombre: values.nombre,
        codigo: values.codigo,
        configuracion: {
          intervalo: values.intervalo,
          cupos: values.cupos,
          horario: values.horario.map((day) => {
            return {
              dia: day.dia,
              inicio: day.horario[0].format('HH:mm'),
              fin: day.horario[1].format('HH:mm')
            };
          }),
          asuetos: values.asuetos
        }
      };

      if (body.configuracion.horario.length === 0) {
        message.error('Debe seleccionar al menos un día de atención');
        return;
      }

      const response = await agendaService.createAgenda(body)

      await agendaService.getMyAgendas();

      message.success('Agenda creada correctamente');

      navigate(`/agenda/${response.id}`);
    } catch (error) {
      if (error.response) {
        message.error(error.response.data?.error);
      } else {
        message.error('Error al crear la agenda');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      initialValues={{
        intervalo: 30,
        cupos: 1,
        horario: [],
        asuetos: []
      }}
    >

      <Title level={4}>Configuración de la agenda</Title>

      <Form.Item label="Nombre de la agenda" name="nombre" rules={[{ required: true, message: 'Ingrese el nombre de la agenda' }]}>
        <Input />
      </Form.Item>

      <Form.Item 
        label="Código de la agenda" 
        name="codigo" 
        rules={[{ required: true, message: 'Ingrese el código de la agenda'}, { max: 20,  message: 'El código no puede tener más de 50 caracteres' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item label="Intervalo de tiempo para cada cita" name="intervalo" rules={[{ required: true, message: 'Ingrese el intervalo de tiempo' }]}>
        <InputNumber addonAfter="minutos" />
      </Form.Item>

      <Form.Item label="Citas por intervalo" name="cupos" rules={[{ required: true, message: 'Ingrese la cantidad' }]}>
        <InputNumber min={1} />
      </Form.Item>

      <Divider />
      <Title level={4}>Horarios de atención</Title>

      <Form.List name="horario">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <Space key={key} className='flex mt-5' align="baseline">
                <Form.Item
                  {...restField}
                  name={[name, 'dia']}
                  rules={[{ required: true, message: 'Seleccione un día' }]}
                >
                  <Select placeholder="Seleccione un día">
                    {daysOfWeek.map((day, dayIndex) => (
                      <Select.Option key={dayIndex} value={daysOfWeekKeys[dayIndex]}>
                        {day}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, 'horario']}
                >
                  <RangePicker
                    format="HH:mm"
                    placeholder={['Inicio', 'Fin']}
                    minuteStep={15}
                    required={true}
                  />
                </Form.Item>
                <MinusCircleOutlined onClick={() => remove(name)} />
              </Space>
            ))}
            <Form.Item>
              <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                Agregar dia
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>

      <Divider />
      <Title level={4}>Asuetos</Title>

      <Form.List name="asuetos">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Space key={key} className='flex mt-5' align="baseline">
                <Form.Item {...restField} name={[name, 'fecha']}>
                  <DatePicker placeholder='Seleccione la fecha' />
                </Form.Item>
                <Form.Item {...restField} name={[name, 'nombre']}>
                  <Input placeholder="Nombre del asueto" />
                </Form.Item>
                <MinusCircleOutlined onClick={() => remove(name)} />
              </Space>
            ))}
            <Form.Item>
              <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                Agregar asueto
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Guardar
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateAgendaForm;