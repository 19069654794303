import React from 'react'

export default function Dashboard() {
  return (
    <div>
      Dashboard
    </div>
  );
}

