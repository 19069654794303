// Actions
const SET_BOOKINGS = 'SET_BOOKINGS';
const SET_BOOKING_DETAIL = 'SET_BOOKING_DETAIL';

// Action creator to add bookings
export const addBookings = (bookings) => ({
  type: SET_BOOKINGS,
  payload: bookings,
});

// Action creator to set booking detail
export const setBookingDetail = (bookingDetail) => ({
  type: SET_BOOKING_DETAIL,
  payload: bookingDetail,
});

// Reducer
const initialState = {
  bookings: [],
  bookingDetail: {}, // New state property
};

const bookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BOOKINGS:
      return {
        ...state,
        bookings: action.payload,
      };
    case SET_BOOKING_DETAIL:
      return {
        ...state,
        bookingDetail: action.payload,
      };
    default:
      return state;
  }
};

export default bookingReducer;
