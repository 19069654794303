import React, { useEffect, useRef, useState } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction'
import esLocale from '@fullcalendar/core/locales/es'
import { useParams } from 'react-router-dom';
import reservaService from '../../services/reserva/reserva-service';
import { Button, Col, message, Row, Spin, Typography, Grid, Drawer, Tag } from 'antd';
import { SettingOutlined, ProductOutlined } from '@ant-design/icons';
import agendaService from '../../services/agenda/agenda-service';
import BookingDetail from '../../components/Agenda/BookingDetail'
import UpdateAgendaForm from '../../components/Agenda/UpdateAgendaForm'
import { useAuth } from '../../hooks/useAuth';
import { useSelector } from 'react-redux';
import Elements from '../../components/Agenda/ElementsList';


const { Title } = Typography;
const { useBreakpoint } = Grid;

export default function Agenda() {
  const { user } = useAuth();
  const calendarRef = useRef(null)
  const screens = useBreakpoint();
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfigOpen, setIsConfigOpen] = useState(false);
  const [isElementsOpen, setIsElementsOpen] = useState(false);

  const agenda = useSelector(state => state.agenda.selectedAgenda);
  const bookings = useSelector(state => state.booking.bookings);

  const fetchBookings = async () => {
    setLoading(true);
    try {
      await reservaService.getBookingsByAgendaId(id);
    } catch (error) {
      console.error(error);
      message.error('Error al obtener las reservas');
    } finally {
      setLoading(false);
    }
  };

  const fetchAgenda = async () => {
    try {
      await agendaService.getAgendaById(id);
    } catch (error) {
      console.error(error);
      message.error('Error al obtener la agenda');
    }
  };

  useEffect(() => {
    fetchBookings();
    fetchAgenda();
    setIsConfigOpen(false);
  }, [id]);

  const handleEventClick = async (info) => {
    try {
      await reservaService.getBookingByCode(info.event.extendedProps.code);
      setIsModalOpen(true);

    } catch (error) {
      console.error(error)
      message.error('Error al obtener la reserva');
    }
  }

  const handleDateClick = (info) => {
    calendarRef.current.getApi().changeView('timeGridDay', info.dateStr);
  }

  const handleCancel = () => {
    setIsModalOpen(false);
  }

  const handleUpdateAgenda = () => {
    setIsConfigOpen(false);
    fetchAgenda();
  }

  const handleBookingDelete = () => {
    setIsModalOpen(false);
    fetchBookings();
    message.info('Reserva eliminada');
  }


  if (loading) {
    return (
      <div style={{ height: '100vh' }} className='flex items-center justify-center'>
        <Spin size="large">
          <div className="content" />
        </Spin>
      </div>
    )
  } else {
    return (
      <>
        <Row align="middle" className='mb-3'>
          <Col md={12} sm={24}>
            <Row align="middle">
              <Col>
                {agenda.activo ? <Tag color="success">Activa</Tag> : <Tag color="error">Inactiva</Tag>}
              </Col>
              <Col>
                <Title level={screens.sm || screens.xs ? 4 : 2} className='mt-2 ml-1'>{agenda.nombre}</Title>
              </Col>
            </Row>
          </Col>
          <Col md={12} sm={24} style={{ textAlign: 'right' }}>
            {user.role.nombre === 'Administrador' && screens.md && (
              <>
                <Button icon={<SettingOutlined />} onClick={() => setIsConfigOpen(true)}>Configuración</Button>
                <Button icon={<ProductOutlined />} onClick={() => setIsElementsOpen(true)} className='ml-3'>Elementos</Button>
              </>
            )}
          </Col>
        </Row>
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
          headerToolbar={
            screens.md ?
              { start: 'prev,next', center: 'title', end: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek' } :
              { start: '', center: 'title', end: '' }
          }
          footerToolbar={
            screens.md ?
              { start: '', center: '', end: '' } :
              { start: 'prev,next', center: '', end: 'listDay,timeGridDay' }
          }
          initialView={screens.md ? 'dayGridMonth' : 'listDay'}
          events={bookings}
          locale={esLocale}
          height={600}
          eventClick={handleEventClick}
          dateClick={handleDateClick}
        />
        <Drawer title="Configuración" onClose={() => setIsConfigOpen(false)} open={isConfigOpen}>
          <UpdateAgendaForm onSuccess={handleUpdateAgenda} />
        </Drawer>
        <Drawer title="Elementos" onClose={() => setIsElementsOpen(false)} open={isElementsOpen}>
          <Elements />
        </Drawer>
        <BookingDetail open={isModalOpen} onCancel={handleCancel} onDelete={handleBookingDelete} />
      </>
    );
  }
}

