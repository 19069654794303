import React, { useEffect, useState } from 'react';
import { Typography, Row, Col, Layout, Card, message } from 'antd';
import { CalendarTwoTone, ContactsTwoTone, ContainerTwoTone, AppstoreTwoTone } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { Helmet } from "react-helmet";
import dayjs from 'dayjs';
import reservaService from '../services/reserva/reserva-service';

const { Text, Title } = Typography;
const { Header, Content } = Layout;

const BookingInfo = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const bookingDetail = useSelector(state => state.booking.bookingDetail);
  const [qrCode, setQrCode] = useState('');
  const [error, setError] = useState(false);
  const { user } = useAuth();

  const isAdmin = user?.role?.nombre === 'Administrador';

  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code');

  useEffect(() => {
    const fetchBookingDetail = async () => {
      await reservaService.getBookingByCode(code);
    };

    const fetchQrCode = async () => {
      const response = await reservaService.generateQrCode(code);
      setQrCode(response);
    };

    const loadBookingDetail = async () => {
      try {
        await fetchBookingDetail();
        await fetchQrCode();
        setError(false);
      } catch (error) {
        message.error('Error al obtener la reserva, puede que no exista o haya sido eliminada.');
        setError(true)
      }
    };

    if (code) {
      loadBookingDetail();
    }
  }, [code, dispatch]);

  // TODO: (RES01)
  const { fecha, horaInicio, horaFin, detalle, codigo, opciones, Agenda , cliente: { nombre, codigo: codigoCliente, contacto, telefono } = {} } = bookingDetail;

  const pageTitle = Agenda?.empresa?.nombre ?  `Calendar - ${Agenda?.empresa?.nombre}` : 'Calendar';  
  
  // verificar si la reserva esta vencida
  const endDatetime = dayjs(`${fecha} ${horaFin}`);
  const isBookingExpired = endDatetime.isBefore(dayjs());

  return (
    <div className='flex justify-center' style={{ backgroundColor: '#f5f5f5' }}>
      <Helmet>
        <title>{ `${pageTitle}` }</title>
        <meta name="description" content="Confirmación de reserva" />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content="Confirmación de reserva" />
      </Helmet>
      <Layout style={{ minHeight: '100vh', maxWidth: '720px' }}>
        <Header>
          <Title style={{ color: 'white' }} level={4}>Detalle de la Reserva</Title>
        </Header>
        <Content style={{ padding: '20px' }}>
          {
            error || isBookingExpired ? (
              <Card>
                <Text type='danger'>
                  ({isBookingExpired ? 'La reserva ha expirado' : 'No se encontró la reserva'})
                </Text>
              </Card>
            ) : (
              <Card>
                <Title level={4} className='text-xs-center'>{bookingDetail.titulo}</Title>
                <Row className='text-xs-center'>
                  <Col xs={24} md={12}>
                    <Row className='mt-4'>
                      <Col xs={24} md={4}>
                        <ContainerTwoTone style={{ fontSize: '24px' }} />
                      </Col>
                      <Col xs={24} md={20}>
                        <Text type="secondary">Código de reserva</Text><br />
                        <Text>{codigo}</Text>
                      </Col>
                    </Row>
                    <Row className='mt-4'>
                      <Col xs={24} md={4}>
                        <CalendarTwoTone style={{ fontSize: '24px' }} />
                      </Col>
                      <Col xs={24} md={20}>
                        <Text type="secondary">Fecha y hora</Text><br />
                        <Text>{fecha} {horaInicio} - {horaFin}</Text>
                      </Col>
                    </Row>
                    <Row className='mt-4'>
                      <Col xs={24} md={4}>
                        <ContactsTwoTone style={{ fontSize: '24px' }} />
                      </Col>
                      <Col xs={24} md={20}>
                        <Text type="secondary">Cliente</Text><br />
                        <Text>{nombre}</Text><br />
                        <Text>{codigoCliente}</Text><br />
                        <Text>{contacto}</Text><br />
                        {
                          isAdmin && (
                            <>
                              <Text>{telefono}</Text>
                            </>
                          )
                        }
                      </Col>
                    </Row>
                    {
                      isAdmin && detalle && (
                        <Row className='mt-4'>
                          <Col xs={24} md={4}>
                            <ContainerTwoTone style={{ fontSize: '24px' }} />
                          </Col>
                          <Col xs={24} md={20}>
                            <Text type="secondary">Detalle</Text><br />
                            <Text>{detalle}</Text>
                          </Col>
                        </Row>
                      )
                    }
                    {
                      isAdmin && opciones && opciones?.length > 0 && (
                        <Row className='mt-4'>
                          <Col xs={24} md={4}>
                            <AppstoreTwoTone style={{ fontSize: '24px' }} />
                          </Col>
                          <Col xs={24} md={20}>
                            <Text type="secondary">Opciones</Text><br />
                            {
                              opciones.map(opcion => (
                                <><Text key={opcion.id}>{opcion.nombre}</Text><br /></>
                              ))
                            }
                          </Col>
                        </Row>
                      )
                    }
                  </Col>
                  <Col xs={24} md={12}>
                    <Row className='mt-4'>
                      <Col xs={24} md={4}>
                        <AppstoreTwoTone style={{ fontSize: '24px' }} />
                      </Col>
                      <Col xs={24} md={20}>
                        <Text type="secondary">Validación de cita por QR</Text><br />
                        {qrCode && <img src={qrCode} alt="Código QR" width={210} />}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            )
          }
        </Content>
      </Layout>
    </div>
  );
};

export default BookingInfo;
