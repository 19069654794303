// Acciones
const SET_AGENDAS = 'SET_AGENDAS';
const SET_SELECTED_AGENDA = 'SET_SELECTED_AGENDA';

// Acción creadora para agregar una agenda
export const addAgenda = (agendas) => ({
  type: SET_AGENDAS,
  payload: agendas,
});

// Acción creadora para establecer la agenda seleccionada
export const setSelectedAgenda = (agenda) => ({
  type: SET_SELECTED_AGENDA,
  payload: agenda,
});

// Reducer
const initialState = {
  agendas: [],
  selectedAgenda: {},
};

const agendaReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AGENDAS:
      return {
        ...state,
        agendas: action.payload,
      };
    case SET_SELECTED_AGENDA:
      return {
        ...state,
        selectedAgenda: action.payload,
      };
    default:
      return state;
  }
};

export default agendaReducer;