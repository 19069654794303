import api from '../api';

class ElementoService {
  async getElements() {
    const response = await api.get('/elementos');
    return response.data;
  }

  async getElementById(id) {
    const response = await api.get(`/elementos/${id}`);
    return response.data;
  }

  async createElement(element) {
    await api.post('/elementos', element);
  }

  async updateElement(id, element) {
    await api.put(`/elementos/${id}`, element);
  }
}

const elementoService = new ElementoService();
export default elementoService;