import api from '../api';
import store from '../../store';
import dayjs from 'dayjs';

class ReservaService {
  async getBookingsByAgendaId(idAgenda) {
    const response = await api.get(`/reservas?idAgenda=${idAgenda}`);

    const bookings = response.data.map((booking) => {
      return {
        title: `${booking.cliente?.nombre} ${booking.titulo ?? ''}`,
        start: dayjs(booking.fecha + ' ' + booking.horaInicio).toDate(),
        end: dayjs(booking.fecha + ' ' + booking.horaFin).toDate(),
        code: booking.codigo
      };
    });

    store.dispatch({ type: 'SET_BOOKINGS', payload: bookings });
  }

  async getBookingByCode(code) {
    const response = await api.get(`/reservas/${code}`);

    store.dispatch({ type: 'SET_BOOKING_DETAIL', payload: response.data });
    console.log('dispatching booking detail');
  }

  async updateBooking(id, booking) {
    await api.put(`/reservas/${id}`, booking);
  }

  async deleteBooking(id) {
    await api.delete(`/reservas/${id}`);
  }

  async generateQrCode(code) {
    const response = await api.get(`/reservas/qr-code?code=${code}`);

    return response.data.qrCodeDataURL;
  }
}

const reservaService = new ReservaService();
export default reservaService;