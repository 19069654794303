import { Menu } from 'antd';
import { DashboardOutlined, BookOutlined, CalendarOutlined, UserOutlined, ProductOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../../hooks/useAuth";
import { useSelector } from 'react-redux';

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const AuthMenu = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const agendas = useSelector(state => state.agenda.agendas);

  const menuItems = [
    user.role?.nombre === 'Administrador' && getItem('Dashboard', 'dashboard', <DashboardOutlined />, null, 'item'),
    getItem('Agendas', 'calendar', <CalendarOutlined />, agendas.map((agenda) => (
      getItem(agenda.nombre, `/agenda/${agenda.id}`, <CalendarOutlined />, null, 'item')
    )), 'list'),
    {
      type: 'divider',
    },
    user.role?.nombre === 'Administrador' && getItem('Crear Agenda', 'create-agenda', <BookOutlined />, null, 'item'),
    user.role?.nombre === 'Administrador' && getItem('Elementos', 'elementos', <ProductOutlined />, null, 'item')
  ];

  const menuItems2 = [
    getItem('Cuenta', 'profile', <UserOutlined />, [
      getItem('Cerrar Sesión', '/logout', null, null, 'item')
    ], 'list')
  ];

  const onClick = (e) => {
    navigate(e.key);
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: 'calc(100% - 80px)' }}>
      <div>
        <Menu
          onClick={onClick}
          mode="inline"
          defaultOpenKeys={['calendar']}
          items={menuItems}
        />
      </div>
      <div>
        <Menu
          onClick={onClick}
          mode="vertical"
          items={menuItems2}
        />
      </div>
    </div>
  );
}

export default AuthMenu;