import { Button, Flex, Form, Input, Space, Spin, message } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import elementoService from "../../services/elemento/elemento-service";
import Title from "antd/es/typography/Title";

export default function ElementForm({ elementId }) {

  const [element, setElement] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const initialValues = {
    nombre: element.elemento?.nombre || '',
    codigo: element.elemento?.codigo || '',
    opciones: element.opciones?.map(opcion => ({ id: opcion.id, nombre: opcion.nombre })) || []
  };

  useEffect(() => {
    const getOptions = async () => {
      try {
        setIsLoading(true);
        const response = await elementoService.getElementById(elementId)
        setElement(response)
      } catch (error) {
        message.error('Error al obtener las opciones del elemento');
      } finally {
        setIsLoading(false);
      }
    }

    getOptions()
  }, [elementId])

  if (isLoading) return (
    <Flex align="center" gap="middle">
      <Spin size="large" />
    </Flex>
  )

  const onFinish = async (values) => {
    try {
      setIsSubmitting(true);
      const body = {
        nombre: values.nombre,
        codigo: values.codigo,
        descripcion: "",
        opciones: values.opciones.map(opcion => ({ id: opcion.id ?? null, nombre: opcion.nombre }))
      }

      await elementoService.updateElement(elementId, body);
      message.success('Elemento actualizado correctamente');
    } catch (error) {
      if (error.response) {
        message.error(error.response.data?.error);
      } else {
        message.error('Error al actualizar el elemento');
      }
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <Form
    layout="vertical"
      initialValues={initialValues}
      onFinish={onFinish}
    >
      <Form.Item name="nombre" label="Nombre del elemento" rules={[{ required: true, message: 'Ingrese el nombre' }]}>
        <Input placeholder="Nombre del elemento" />
      </Form.Item>

      <Form.Item name="codigo" label="Código del elemento" rules={[{ required: true, message: 'Ingrese el código', max: 20 }]}>
        <Input placeholder="Código del elemento" />
      </Form.Item>

      <Title level={5}>Opciones</Title>
      <Form.List name="opciones">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Space key={key} className='flex' align="baseline">
                <Form.Item {...restField} name={[name, 'id']} style={{ display: 'none' }}>
                  <Input type="hidden" />
                </Form.Item>
                <Form.Item {...restField} name={[name, 'nombre']}>
                  <Input placeholder="Nombre de la opción" />
                </Form.Item>
                <MinusCircleOutlined onClick={() => remove(name)} />
              </Space>
            ))}
            <Form.Item>
              <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                Agregar opción
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={isSubmitting} block>
          Guardar
        </Button>
      </Form.Item>
    </Form>
  )
}