import React from 'react';
import CreateAgendaForm from '../../components/Agenda/CreateAgendaForm';

const CreateAgenda = () => {
  return (
    <CreateAgendaForm />
  )
};

export default CreateAgenda;
