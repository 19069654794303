import { Button, Checkbox, Flex, Form, Input, Space, Spin, message } from "antd";
import { useEffect, useState } from "react";
import elementoService from "../../services/elemento/elemento-service";
import Title from "antd/es/typography/Title";
import { useSelector } from "react-redux";
import agendaService from "../../services/agenda/agenda-service";

export default function ElementForm({ elementId }) {

  const [element, setElement] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [cuposEnabled, setCuposEnabled] = useState([]); 

  const agenda = useSelector(state => state.agenda.selectedAgenda);

  useEffect(() => {
    if (agenda.opciones) {
      element.opciones?.forEach((elementOption, index) => {
        const agendaOption = agenda.opciones.find(agendaOption => agendaOption.id === elementOption.id);
        if (agendaOption !== undefined) {
          setCuposEnabled(prevState => [...prevState, index + 1]);
        }
      });
    }
  }, [agenda, element]);

  useEffect(() => {
    const getOptions = async () => {
      try {
        setIsLoading(true);
        const response = await elementoService.getElementById(elementId)
        setElement(response)
      } catch (error) {
        message.error('Error al obtener las opciones del elemento');
      } finally {
        setIsLoading(false);
      }
    }

    getOptions()
  }, [elementId])


  if (isLoading) return (
    <Flex align="center" gap="middle">
      <Spin size="large" />
    </Flex>
  )

  const onFinish = async (values) => {
    await saveOptions(values);
    await reloadInfo();
  }

  const saveOptions = async (values) => {
    try {
      setIsSubmitting(true);
      const opciones = values.opciones
        .map(opcion => ({ 
          id: opcion.id, 
          activo: !opcion.activo, 
          cupos: opcion.cupos
        }));

      await agendaService.setOptions(agenda.id, { idElemento: elementId, opciones });
      message.success('Configuración actualizada correctamente');
    } catch (error) {
      message.error('Error al actualizar el elemento');
    } finally {
      setIsSubmitting(false);
    }
  }

  const reloadInfo = async () => {
    try {
      setIsLoading(true);
      const response = await elementoService.getElementById(elementId)
      await agendaService.getAgendaById(agenda.id);
      setElement(response)
    } catch (error) {
      message.error('Ocurrió un error al recargar la información');
    } finally {
      setIsLoading(false);
    }
  }

  const handleCheckboxChange = (checked, index) => {
    setCuposEnabled(prevState => {
      if (checked) {
        return [...prevState, index];
      } else {
        return prevState.filter(i => i !== index);
      }
    });
  };
  
  const initialValues = {
    opciones: element.opciones?.map(elementOption => {
      const agendaOption = agenda.opciones.find(agendaOption => agendaOption.id === elementOption.id);
      return {
        id: elementOption.id,
        nombre: elementOption.nombre,
        activo: !Boolean(agendaOption),
        cupos: agendaOption?.AgendaOpcion?.cupos
      }
    
    }) || []
  };

  return (
    <Form
      layout="vertical"
      initialValues={initialValues}
      onFinish={onFinish}
    >
      <Title level={4}>{element.elemento?.nombre}</Title>
      <Form.List name="opciones">
        {(fields) => (
          <>
            {fields.map((field, index) => (
                <Space key={field.key} className='flex' align="baseline">
                  <Form.Item 
                    name={[field.name, 'activo']} 
                    valuePropName="checked"
                    getValueProps={(value) => ({ checked: !value })}
                    getValueFromEvent={(e) => {
                      // le sumo 1 porque el index empieza en 0 y me da problemas cuando hago las comparaciones (tambien en el input de abajo)
                      handleCheckboxChange(e.target.checked, index + 1);
                      return !e.target.checked;
                    }}
                  >
                    <Checkbox />
                  </Form.Item>
                  <Form.Item name={[field.name, 'id']} style={{ display: 'none' }}>
                    <Input type="hidden" />
                  </Form.Item>
                  <Form.Item name={[field.name, 'nombre']}>
                    <Input disabled/>
                  </Form.Item>
                  <Form.Item name={[field.name, 'cupos']}>
                    <Input placeholder="Cupos" disabled={!cuposEnabled.find(i => i === index + 1)} /> 
                  </Form.Item>
                </Space>
              ))}
          </>
        )}
      </Form.List>
      <Form.Item>
        <Button type="primary" disabled={!element.opciones?.length > 0} htmlType="submit" loading={isSubmitting} block>
          Guardar
        </Button>
      </Form.Item>
    </Form>
  )
}