import React, { useEffect, useMemo, useState } from 'react';
import { Typography, Row, Col, Modal, Button } from 'antd';
import { CalendarTwoTone, PhoneTwoTone, ProfileTwoTone, ContactsTwoTone, DeleteOutlined, ContainerTwoTone, AppstoreTwoTone } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import reservaService from '../../services/reserva/reserva-service';
import dayjs from 'dayjs';

const { Text, Title } = Typography;

const BookingDetail = ({ open, onCancel, onDelete }) => {
  const bookingDetail = useSelector(state => state.booking.bookingDetail);
  const bookings = useSelector(state => state.booking.bookings);

  const dayBookings = useMemo(() => {
    return bookings.filter(booking => {
      return dayjs(booking.start).isSame(dayjs(bookingDetail.fecha), 'day');
    }).sort((a, b) => a.start - b.start);
  }, [bookings, bookingDetail]);

  const [currentBookingIndex, setCurrentBookingIndex] = useState(1);

  useEffect(() => {
    if (bookingDetail && dayBookings.length > 0) {
      const index = dayBookings.findIndex(booking => booking.code === bookingDetail.codigo);
      if (index !== -1) {
        setCurrentBookingIndex(index);
      }
    }
  }, [bookingDetail, dayBookings]);

  const { fecha, horaInicio, horaFin, detalle, codigo, opciones, cliente: { nombre, codigo: codigoCliente, contacto, telefono } = {} } = bookingDetail;

  const handlePrevious = async () => {
    if (currentBookingIndex > 0) {
      const newIndex = currentBookingIndex - 1;
      setCurrentBookingIndex(newIndex);
      await reservaService.getBookingByCode(dayBookings[newIndex].code);
    }
  }

  const handleNext = async () => {
    if (currentBookingIndex < dayBookings.length - 1) {
      const newIndex = currentBookingIndex + 1;
      setCurrentBookingIndex(newIndex);
      await reservaService.getBookingByCode(dayBookings[newIndex].code);
    }
  }

  const handleTitleChange = async (newTitle) => {
    await reservaService.updateBooking(bookingDetail.id, { titulo: newTitle });
    await reservaService.getBookingByCode(bookingDetail.codigo);
  }

  const handleDelete = async () => {
    Modal.confirm({
      title: 'Confirmar eliminación de reserva',
      content: '¿Está seguro que desea eliminar la reserva?',
      onOk: async () => {
        await reservaService.deleteBooking(bookingDetail.codigo);
        onDelete();
      },
    });
  }

  return (
    <>
      <Modal
        open={open}
        onCancel={onCancel}
        footer={[
          <Button key='delete' icon={<DeleteOutlined />} onClick={handleDelete} />,
          <Button key="previous" onClick={handlePrevious}>
            Anterior
          </Button>,
          <Button key="next" onClick={handleNext}>
            Siguiente
          </Button>
        ]}
      >
        <Row justify="space-between" align="middle" className='</Button>mt-4'>
          <Col>
            <Title editable={{
              tooltip: 'Click para agregar o editar el título de la reserva',
              onChange: handleTitleChange,
            }} level={5}>{bookingDetail.titulo}</Title>
          </Col>
          <Col>
            <Text type="secondary">Reserva {currentBookingIndex + 1}/{dayBookings.length}</Text>
          </Col>
        </Row>

        <Row className='mt-4'>
          <Col span={3}>
            <CalendarTwoTone style={{ fontSize: '24px' }} />
          </Col>
          <Col span={21}>
            <Text type="secondary">Fecha y hora</Text><br />
            <Text>{fecha} {horaInicio} - {horaFin}</Text>
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col span={3}>
            <ContactsTwoTone style={{ fontSize: '24px' }} />
          </Col>
          <Col span={21}>
            <Text type="secondary">Cliente</Text><br />
            <Text>{nombre}</Text><br />
            <Text>{codigoCliente}</Text><br />
            <Text>{contacto}</Text><br />
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col span={3}>
            <PhoneTwoTone style={{ fontSize: '24px' }} />
          </Col>
          <Col span={21}>
            <Text type="secondary">Contacto</Text><br />
            <Text>{telefono}</Text>
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col span={3}>
            <ContainerTwoTone style={{ fontSize: '24px' }} />
          </Col>
          <Col span={21}>
            <Text type="secondary">Código de reserva</Text><br />
            <Text>{codigo}</Text>
          </Col>
        </Row>
        {
          opciones && opciones?.length > 0 && (
            <Row className='mt-4'>
              <Col span={3}>
                <AppstoreTwoTone style={{ fontSize: '24px' }} />
              </Col>
              <Col span={21}>
                <Text type="secondary">Opciones</Text><br />
                {
                  opciones.map(opcion => (
                    <><Text key={opcion.id}>{opcion.nombre}</Text><br /></>
                  ))
                }
              </Col>
            </Row>
          )
        }
        {
          detalle && (
            <Row className='mt-4'>
              <Col span={3}>
                <ProfileTwoTone style={{ fontSize: '24px' }} />
              </Col>
              <Col span={21}>
                <Text type="secondary">Detalle</Text><br />
                <Text>{detalle}</Text>
              </Col>
            </Row>
          )
        }
      </Modal>
    </>
  );
};

export default BookingDetail;
