import api from '../api';
import store from '../../store';

class AgendaService {
  async getMyAgendas() {
    const user = JSON.parse(localStorage.getItem('user'));

    const response = await api.get(`/agendas?companyId=${user.company.id}`); // TODO: Fix this, shoul be in the backend and use auth
    store.dispatch({ type: 'SET_AGENDAS', payload: response.data?.data });

    return response.data?.data;
  }

  async getAgendaById(id) {
    const response = await api.get(`/agendas/${id}`);
    store.dispatch({ type: 'SET_SELECTED_AGENDA', payload: response.data });

    return response.data;
  }

  async createAgenda(data) {
    const response = await api.post('/agendas', data);

    return response.data;
  }

  async toogleAgendaStatus(id) {
    const response = await api.put(`/agendas/${id}/toggle-status`);

    return response.data;
  }

  async updateAgenda(id, data) {
    const response = await api.put(`/agendas/${id}`, data);

    return response.data;
  }

  async deleteAgenda(id) {
    const response = await api.delete(`/agendas/${id}`);

    return response.data;
  }

  async setOptions(id, data) {
    const response = await api.put(`/agendas/${id}/opciones`, data);

    return response.data;
  }
}

const agendaService = new AgendaService();
export default agendaService;