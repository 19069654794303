import api from '../api';

class AuthService {
  async login(username, password) {
    const response = await api.post('/auth/login', {
        username,
        password
      });

    return response.data;
  }

  async verifyToken(token) {
    const response = await api.get('/auth/verify-token', {
      headers: {
        Authorization: token
      }
    });

    return response.data;
  }
}

const authService = new AuthService();
export default authService;